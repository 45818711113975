<template>
    <div id="rule">
        <h1>标题</h1>
        <p></p>
        <p></p>
    </div>
</template>
<script>
export default {
    name:"rule",
    data(){
        return{

        }
    }
}
</script>
<style lang="scss">
body,html{
    min-width: auto;
}
@function s($num) {
	@return $num / (3.75) +vw
}
#rule{
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    h1{
        font-size: s(18);
        margin-bottom: s(10);
    }
}
</style>